<template>
  <div class="chat-box" id="chat-box">
    <Nav :title="title"></Nav>
    <div class="chat-window" id="chat-window">
      <div class="chat-messages" ref="chatMessages" id="chat-messages" v-show="!$store.state.loading" @scroll="handleScroll" >
        <div  id="chat-message-dialog" v-for="(msg, i) in messages" :key="i" :class="msg.sender === 'bot' ? 'chat-right': 'chat-left'">
          <div class="chat-message left" v-if="msg.sender === 'bot'">
            <div class="chat-avatar">
              <img :src="logo" alt="avatar">
            </div>
            <div class="chat-message-text left" v-if="i === messages.length - 1 && i && isLastMsg">
                <div ref="output" class="output-container">
                  <div class="output-html">
                    <div class="text-container">
                      <span>{{ printedText }}</span>
                      <span ref="cursor" class=" cursor-waiting" :class="{ visible: cursorVisible }" v-if="isWaiting"></span>
                      <span ref="cursor" class=" cursor" :class="{ visible: cursorVisible }" v-else></span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="chat-message-text left" v-else @click="copyToClipboard(msg.text)" :style="{'background': $store.state.userAgent === 'PC' ? '#f7f7f8': '#fff'}">
              <p>{{ msg.text }}</p>
            </div>
          </div>
          <div class="chat-message right" v-else>
            <div class="chat-message-text right" @click="copyToClipboard(msg.text)" :style="{'background': $store.state.userAgent === 'PC' ? '#f7f7f8': '#fff'}">
              <p>{{ msg.text }}</p>
            </div>
            <div class="chat-avatar">
              <img :src="avatarUrl" alt="avatar">
            </div>
          </div>
        </div>
        <!-- 显示加载中的文本 -->
        <div v-if="isLoading">Loading...</div>
        <!-- 显示加载失败的文本 -->
        <div v-if="isLoadFailed">Failed to load.</div>
      </div>
      <div class="chat-input" id="chat-input">
        <textarea  v-model="inputText" type="text" class="message-input" @keyup.enter="sendMessage" placeholder="说点什么..." 
                   ref="textarea" @input="adjustTextareaHeight" wrap="soft" rows="1" @keydown.enter.prevent></textarea>
        <button :class="inputText.length > 0 ? 'send-button-active' : 'send-button'" @click="sendMessage">发送</button>
      </div>
    </div>
  </div>
</template>

<script>
import { sparkAssitantChat, addchat, querychat, wxSdk } from "../../api/api";
import Nav from "../../components/nav/index.vue"
const seo = require('../../../config/index')
import wx from "weixin-js-sdk";
import {Toast} from 'vant'
import { getopenIdByCode } from '@/utils/index';

export default {
  name: "Chat",
  data() {
    return {
      messages: [],
      // 1.人物扮演、角色扮演
      title: '聊个小天',
      inputText: "",
      logo: seo.config.logo,
      avatarUrl: '',
      isBlinking: true,
      limit: 10,
      page: 1,
      loadingMoreMsg: false, // 上拉加载消息
      isEnd: false, // 是否已经加载完毕所有记录
      pageSize: 10, // 每页记录数
      total: 0, // 总记录数
      isLastMsg: false, // 判断是否为实时的最新回答
      isLoading: false, // 是否正在加载数据
      isLoadFailed: false, // 是否加载数据失败
      printedText: '',  // 用于存储逐个打印的文字
      cursorVisible: true,  // 控制光标的可见性
      outputHtml: '',
      isWaiting: true, // 是否是光标等待
      isLogin: localStorage.getItem('token') ? true : false,
    };
  },
  components: {
    Nav,
  },
  activated(){
    this.wxSdk()
    this.isLogin = localStorage.getItem('token') ? true : false
    this.avatarUrl = this.isLogin ? JSON.parse(localStorage.getItem('userInfo'))[0].miniAvatarUrl : require('../../assets/images/avatar.png')
    // 如果是新注册用户，使用默认头像
    if(this.avatarUrl.length === 0){
      this.avatarUrl = require('../../assets/images/avatar.png')
    }
    if(this.$store.state.userAgent === 'PC'){
      document.getElementById("chat-window").style.cssText= 'width:750px; position:inherit;margin:0 auto;';
      document.getElementById("chat-input").style.cssText= 'width: 750px;position: fixed;margin: 0 auto;';
      document.getElementById("chat-messages").style.cssText= 'background:#fefefe; padding: 20px 10px 90px; margin: 50px 0 0;';
    }
    if(this.isLogin){
      this.messages.length = 0
    } else {
      this.resetMsg()
    }
    this.page = 1
    this.getChatHistory()
    window.addEventListener('scroll', this.handleScroll)
    getopenIdByCode()
  },
  deactivated(){
    window.removeEventListener(this.handleScroll)
  },
  methods: {
    // 发送问题给chatgpt
    async sendMessage() {
      if (this.inputText.trim().length > 0){
        this.isLogin = localStorage.getItem('token') ? true : false
        if(!this.isLogin && this.messages.length > 4){
          this.inputText = "";
          this.$nextTick(()=>{
            this.adjustTextareaHeight()
          })
          sessionStorage.setItem('fromPath','chat')
          this.$router.push({ name:'Login', params:{ text: '继续聊' } })
        } else {
          this.messages.push({ text: this.inputText, sender: "user" });
          let uid = this.isLogin ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
          // 光标闪烁等待效果
          this.title = '努力解答中...'
          this.messages.push({ text: '', sender: "bot" });
          this.isLastMsg = true
          this.printMsg('waiting')
          let params = this.inputText
          this.inputText = "";
          // 滚动到底部
          this.scrollToEndPlus()
          // 远程请求结果
          let res = await sparkAssitantChat({ text: params, uid: uid })
          this.messages.pop()
          if(res.data.message === 'success'){
            // 监管
            // Toast('抱歉，服务器繁忙中...');
            // 远程保存聊天记录
            this.isLogin && addchat({ sender: uid, receiver: 'bot', message: params })
            let content = res.data.data
            this.messages.push({ text: content, sender: "bot" });
            this.title = '聊个小天'
            this.isLastMsg = true
            this.$nextTick(() => {
              // 远程保存机器人回答
              setTimeout(()=>{  // 保证先保存提问，再保存回答
                this.isLogin && addchat({ sender: 'bot', receiver: uid, message: content })
              },0)
              this.$refs.textarea.focus()
              // 流式打印效果
              this.printMsg()
            });
          } else {
            this.title = '聊个小天'
            Toast('会员积分不足');
          }
        }
      } else {
        Toast('请输入')
      }
    },
    printMsg(type){
      const lastOutputEl = this.$refs.output
      const cursorEl = this.$refs.cursor;
      this.isWaiting = type === 'waiting' ? true : false
      if (lastOutputEl && cursorEl) {
        const lastList = this.messages[this.messages.length - 1]
        let index = 0
        this.printedText = ''
        const outputIntervalId = setInterval(() => {
          const outputChar = lastList.text.charAt(index)
          this.printedText += outputChar
          index++
          if (index > lastList.text.length) {
            clearInterval(outputIntervalId)
            if(type !== 'waiting'){
              this.isLastMsg = false
              this.cursorVisible = false; // 停止闪烁，隐藏光标
            }
          }
          // 滚动到底部
          this.scrollToEndPlus()
        }, 50) // 每150毫秒输出一个字符
      }
    },
    getChatHistory(){
      let uid = this.isLogin ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
      if(this.isLogin){
        if(!this.loadingMoreMsg){
          this.$store.commit('Set_Loading_State', true)
        }
        let chatMessages = this.$refs.chatMessages;
        let oldItemsHeight = chatMessages.scrollHeight;
        // 显示加载中的文本
        this.isLoading = true;
        querychat({ limit: this.limit, page: this.page, uid: uid }).then(res=>{
          this.total = res.data.data.count
          if(res.data.data.count === 0){
            let uid = this.isLogin ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
            // 保证至少有引导语
            if(this.messages.length === 0){
              this.resetMsg()
            }
            // 远程保存聊天记录
            addchat({ sender: 'bot', receiver: uid, message: this.messages[0].text })
          }
          let tempMsg = []
          res.data.data.rows.forEach(row=>{
            let temp = {}
            temp.text = row.message // 聊天内容
            temp.sender = row.sender // 发送方
            temp.receiver = row.receiver // 接收方
            temp.createdAt = row.createdAt
            temp.updatedAt = row.updatedAt
            tempMsg.push(temp)
          })
          this.messages.splice(0,0,...tempMsg)
          if(this.page === 1){
            this.$nextTick(()=>{
              let chatMessages = this.$refs.chatMessages;
              chatMessages.scrollIntoView({ behavior: 'auto', block: 'end' });
            })
          }
          // 停止加载中的状态
          this.isLoading = false;
          // 增加页码
          this.page++
          if(!this.loadingMoreMsg){
            this.scrollToEnd()
            this.$store.commit('Set_Loading_State', false)
          } else {
            // 计算新加载数据的高度
            this.$nextTick(()=>{
              const newItemsHeight = Number(chatMessages.scrollHeight) - Number(oldItemsHeight);
              // 滚动条滚动到原来的位置加上新加载数据的高度
              if(this.$store.state.userAgent === 'Mobile'){
                chatMessages.scrollTo(0, chatMessages.scrollTop + newItemsHeight);
              } else {
                window.scrollTo(0, chatMessages.scrollTop + newItemsHeight);
              }
            })
          }
          this.loadingMoreMsg = false
        }).catch(() => {
          // 显示加载失败的文本
          this.isLoadFailed = true;
          // 停止加载中的状态
          this.isLoading = false;
        });
      } else {
        this.messages = [{
          text: '非常感谢您选择小天AI，我可以提供以下服务：\n1. 旅游助手、扮演当地人 \n2. 纠正语法、给文章润色\n3. 百科问答、学知乎答题\n4. 活动策划、提供好创意\n5. 数学计算、写作业解题\n6. 语言翻译、用英文聊天\n7. 归纳主旨、写文章摘要\n8. 写文案、写小说、写祝福 \n9. 陪伴、倾听、解忧、讲故事\n请继续提问任何问题...',
          sender: "bot"
        }],
        this.scrollToEnd()
      }
    },
    // 待使用-留着
    setCountDown(){
      this.time = 30;
      this.countdown = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.countdown);
        }
      }, 1000);
    },
    resetMsg(){
        this.messages = [{
          text: '非常感谢您选择小天AI，我可以提供以下服务：\n1. 旅游助手、扮演当地人 \n2. 纠正语法、给文章润色\n3. 百科问答、学知乎答题\n4. 活动策划、提供好创意\n5. 数学计算、写作业解题\n6. 语言翻译、用英文聊天\n7. 归纳主旨、写文章摘要\n8. 写文案、写小说、写祝福 \n9. 陪伴、倾听、解忧、讲故事\n请继续提问任何问题...',
          sender: "bot"
        }]
    },
    adjustTextareaHeight() {
      this.$refs.textarea.style.height = 'auto';
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight - 20 + 'px';
    },
    scrollToEndPlus() {
        this.$nextTick(()=>{
          if(this.$store.state.userAgent === 'Mobile'){
            this.adjustTextareaHeight()
            let chatMessages = this.$refs.chatMessages;
            chatMessages.scrollTop = chatMessages.scrollHeight;
          } else {
            window.scrollTo(0, this.$refs.chatMessages.scrollHeight);
          }
        })
    },
    scrollToEnd() {
        this.$nextTick(()=>{
          this.adjustTextareaHeight()
          let chatMessages = this.$refs.chatMessages;
          chatMessages.scrollTop = chatMessages.scrollHeight;
        })
    },
    // 监听页面滚动事件
    handleScroll() {
      // 当滚动到顶部时，刷新聊天记录
      this.$nextTick(()=>{
        let UA = this.$store.state.userAgent
        let chatMessages = this.$refs.chatMessages;
        if (UA === 'Mobile' && chatMessages.scrollTop === 0 || UA === 'PC' && document.documentElement.scrollTop === 0) {
          // 还有没展示的消息，继续请求
          if(this.total > this.messages.length && !this.isLoading){
            this.loadingMoreMsg = true
            this.getChatHistory()
          }
        }
      })
    },
    // 复制到剪切板
    copyToClipboard(text) {
      const textarea = document.createElement("textarea"); // 创建一个textarea元素
      textarea.value = text; // 设置textarea的值
      document.body.appendChild(textarea); // 将textarea添加到DOM中
      textarea.select(); // 选中textarea中的文本
      document.execCommand("copy"); // 将选中的文本复制到剪贴板中
      document.body.removeChild(textarea); // 从DOM中移除textarea
      Toast('已复制');
    },
    resetScrollTop(){
        document.body.scrollTop && document.body.scrollTo({top: 0, behavior: 'smooth'})
        document.documentElement.scrollTop && document.documentElement.scrollTo({top: 0, behavior: 'smooth'})
    },
    wxSdk(){
          let url = seo.config.wxConfig.chatPage.link + this.$route.fullPath
          let context = seo.config.wxConfig.chatPage.desc
          wxSdk({ url:url }).then(res=>{
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: seo.config.wxConfig.appId, // 必填，公众号的唯一标识
              timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.data.signature,// 必填，签名
              jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'] // 必填，需要使用的JS接口列表
            });
            wx.ready(function () {
              wx.checkJsApi({
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'], // 必填，需要使用的JS接口列表
                success: function(res) {
                  // “分享给朋友”及“分享到QQ”
                  if (res.checkResult.updateAppMessageShareData || res.checkResult.onMenuShareAppMessage) {
                    wx.updateAppMessageShareData({ 
                      title: seo.config.wxConfig.chatPage.title, // 分享标题
                      desc: context, // 分享描述
                      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                      imgUrl:  seo.config.wxConfig.chatPage.imgUrl, // 分享图标
                      success: function () {
                        // 设置成功
                      }
                    })
                  }
                  // “分享到朋友圈”及“分享到 QQ 空间”
                  if(res.checkResult.updateTimelineShareData || res.checkResult.onMenuShareTimeline){
                    wx.updateTimelineShareData({ 
                      title: seo.config.wxConfig.chatPage.title, // 分享标题
                      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                      imgUrl: seo.config.wxConfig.chatPage.imgUrl, // 分享图标
                      success: function () {
                        // 设置成功
                      }
                    })
                  }
              }})
            })
          })
    }
  },
};
</script>

<style lang="scss" scoped>
.chat-box {
  
}
.chat-window {
  position: fixed;
  z-index: -1;
  bottom: 150px;
  width: 100%;
  height: calc(100% - 150px);
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  .chat-messages {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding: 10px;
    overflow-y: scroll;
    margin: 50px 0 75px;
    background: #f3f3f3;
    .chat-left {
      display: flex;
      justify-content: right;
      margin-left: 45px;
    }
    .chat-right {
      display: flex;
      justify-content: left;
      margin-right: 45px;
    }
    .chat-message {
      display: flex;
      margin: 10px 0px;
      text-align: left;
    }

    .chat-message.left {
      align-self: flex-start;
      font-size: 17px;
    }

    .chat-message.right {
      align-self: flex-end;
      font-size: 17px;
    }

    .chat-avatar img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .chat-message-text.left {
      background-color: #fff;
      border-radius: 10px;
      padding: 10px 15px;
      margin-left: 5px;
      white-space: pre-wrap;
      p {
        margin: 1px;
      }
    }
    .chat-message-text.right {
      background-color: #fff;
      border-radius: 10px;
      padding: 10px 15px;
      margin-right: 5px;
      white-space: pre-wrap;
      p {
        margin: 1px;
      }
    }
  }
  .chat-input {
    padding: 10px;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    padding: 10px 15px;
    background-image: linear-gradient(-20deg,#fc6076,#ff9a44);
    height: max-content;
    .message-input {
      flex: 1;
      border: none;
      outline: none;
      resize: none;
      font-size: 17px;
      padding: 10px 10px;
      line-height: 25px;
      margin: 10px 0;
      border-radius: 15px;
      background-color: #fff;
      height: 25px;
      overflow:visible;
    }
    .message-input:focus {
      background-color: #fff;
      box-shadow: 0 0 1px 1px #eee;
    }

    .send-button {
      margin-left: 10px;
      border: none;
      outline: none;
      font-size: 14px;
      color: #f3f3f3;
      border-radius: 20px;
      padding: 8px 16px;
      cursor: pointer;
      background-image: linear-gradient(-20deg,rgba(252, 96, 118, 0.5),rgba(255, 154, 68, 0.5));
    }

    .send-button-active {
      margin-left: 10px;
      border: none;
      outline: none;
      font-size: 14px;
      color: #eee;
      border-radius: 20px;
      padding: 8px 16px;
      cursor: pointer;
      background-image: linear-gradient(-20deg,rgba(252, 96, 118, 1),rgba(255, 154, 68, 1));
    }
  }
}

.output-html {
  position: relative;
}

.text-container {
  display: inline-block;
  position: relative;
}

.cursor {
  display: inline-block;
  width: 6px; /* 调整光标宽度 */
  height: 17px;
  line-height: 17px;
  background-color: #000;
  animation: blink-animation 0.9s infinite;
  margin-left: 5px; /* 添加光标与文字之间的间隔 */
  margin-top: 0px;
  vertical-align: middle; /* 光标垂直居中对齐 */
}

.cursor-waiting {
  display: inline-block;
  width: 6px; /* 调整光标宽度 */
  height: 17px;
  line-height: 17px;
  background-color: #000;
  animation: blink-animation 0.9s infinite;
  margin-left: 0px; /* 添加光标与文字之间的间隔 */
  margin-top: 0px;
  vertical-align: middle; /* 光标垂直居中对齐 */
}

@keyframes blink-animation {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

// .cursor-waiting {
//   position: relative;
// }
</style>>
  